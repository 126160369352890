import {
    areasIcon,
    baseSearchIcon,
    documentsIcon, documentsIcon2, dogsIcon,
    exhibitionsIcon, exhibitionsIcon2,
    newsIcon, notificationsIcon,
    pageIcon, photosIcon, presidiumIcon,
    requisitesIcon, responsibleIcon, reviewIcon,
    specializationsIcon, stampsIcon,
    videosIcon,
    walletIcon,
} from './icons';

export const clubMenu = (alias, withNotification) => {
    let menu = [
        {
            id: 1,
            title: 'Мероприятия',
            to: `/exhibitions?Alias=${alias}`,
            exact: true,
            icon: exhibitionsIcon,
        },
    ];

    if (withNotification) {
        menu.push({
            id: 2,
            title: 'Уведомления',
            to: `/club/${alias}/news-feed`,
            exact: true,
            icon: notificationsIcon,
        });
    }

    menu = [
        ...menu,
        // {
        //     id: 3,
        //     title: 'Публикации',
        //     to: `/${alias}/news`,
        //     exact: true,
        //     icon: newsIcon
        // },
        {
            id: 3,
            title: 'Документы',
            to: `/club/${alias}/uploaded-documents/`,
            exact: false,
            icon:   documentsIcon,
        },
        {
            id: 4,
            title: 'Фотогалерея',
            to: `/club/${alias}/gallery`,
            exact: false,
            icon: photosIcon,
        },
        {
            id: 5,
            title: 'Видеозаписи',
            to: `/club/${alias}/video`,
            exact: true,
            icon: videosIcon,
        },
        {
            id: 6,
            title: 'Страница клуба',
            to: `/club/${alias}`,
            exact: true,
            icon: pageIcon,
        },
    ];

    return menu;
};

export const clubDocumentsMenu = alias => {
    const isFederation = alias === 'rkf' || alias === 'rfss' || alias === 'rfls' || alias === 'rfos' || alias === 'oankoo';
    const startUrlPath = isFederation ? `/${alias}` : `/club/${alias}`;

    let menu = [
        {
            id: 1,
            title: 'Оформление документов',
            to: `${startUrlPath}/documents`,
            exact: true,
            icon: documentsIcon2,
        },
        {
            id: 2,
            title: 'Организационная информация',
            to: `${startUrlPath}/documents/responsible`,
            exact: true,
            icon: responsibleIcon,
        },
        {
            id: 3,
            title: 'Клейма',
            to: `${startUrlPath}/documents/stamps`,
            exact: true,
            icon: stampsIcon,
        },
        {
            id: 4,
            title: 'Проведение выставок',
            to: `${startUrlPath}/documents/exhibitions`,
            exact: true,
            icon: exhibitionsIcon2,
        },
    ];

    if (!isFederation) {
        menu.push({
            id: 5,
            title: 'Дрессировка и спорт',
            to: `${startUrlPath}/documents/areas`,
            exact: true,
            icon: areasIcon,
        });
    }

    menu = [
        ...menu,
        {
            id: 6,
            title: 'Поиск по базе РКФ',
            to: `/base-search?clubAlias=${alias}`,
            exact: true,
            icon: baseSearchIcon,
        },
    ];

    if (!isFederation) {
        menu.push({
            id: 7,
            title: 'Реквизиты и размеры взносов',
            to: `${startUrlPath}/documents/bank-details`,
            exact: true,
            icon: requisitesIcon,
        });
    }

    menu = [
        ...menu,
        // по задаче №15172 убран ранее выключенный раздел 'Оценка Федерации'
        // {
        //     id: 8,
        //     title: 'Оценка Федерации',
        //     to: `${startUrlPath}/documents/review`,
        //     exact: true,
        //     disabled: true,
        //     icon: reviewIcon
        // },
        {
            id: 9,
            title: `Страница ${isFederation ? 'федерации' : 'клуба'}`,
            to: startUrlPath,
            exact: true,
            icon: pageIcon,
        },
    ];

    return menu;
};

export const kennelMenu = (alias, withNotification) => {
    let menu = [];

    if (withNotification) {
        menu.push({
            id: 1,
            title: 'Уведомления',
            to: `/kennel/${alias}/news-feed`,
            exact: false,
            icon: notificationsIcon,
        });
    }

    menu = [
        ...menu,
        // {
        //     id: 2,
        //     title: 'Публикации',
        //     to: `/kennel/${alias}/news`,
        //     exact: true,
        //     icon: newsIcon
        // },
        {
            id: 3,
            title: 'Документы',
            to: `/kennel/${alias}/uploaded-documents/`,
            exact: false,
            icon: documentsIcon,
        },
        {
            id: 4,
            title: 'Фотогалерея',
            to: `/kennel/${alias}/gallery`,
            exact: false,
            icon: photosIcon,
        },
        {
            id: 5,
            title: 'Видеозаписи',
            to: `/kennel/${alias}/video`,
            exact: true,
            icon: videosIcon,
        },
        {
            id: 6,
            title: 'Cтраница питомника',
            to: `/kennel/${alias}`,
            exact: true,
            icon: pageIcon,
        },
    ];

    return menu;
};

export const kennelDocumentsMenu = alias => [
    {
        id: 1,
        title: 'Оформление документов',
        to: `/kennel/${alias}/documents`,
        exact: true,
        icon: documentsIcon2,
    },
    {
        id: 2,
        title: 'Организационная информация',
        to: `/kennel/${alias}/documents/responsible`,
        exact: true,
        icon: responsibleIcon,
    },
    {
        id: 3,
        title: 'Поиск по базе РКФ',
        to: `/base-search?nurseryAlias=${alias}`,
        exact: true,
        icon: baseSearchIcon,
    },
    {
        id: 4,
        title: 'Реквизиты и размеры взносов',
        to: `/kennel/${alias}/documents/bank-details`,
        exact: true,
        icon: requisitesIcon,
    },
    {
        id: 5,
        title: 'Оценка Федерации',
        to: `/kennel/${alias}/documents/review`,
        exact: true,
        disabled: true,
        icon: reviewIcon,

    },
    {
        id: 6,
        title: 'Страница питомника',
        to: `/kennel/${alias}`,
        exact: true,
        icon: pageIcon,
    },
];

export const nbcMenu = (alias, withNotification, strOfBreeds) => {
    let menu = [
        {
            id: 1,
            title: 'Мероприятия',
            to: `/exhibitions?${strOfBreeds}`,
            exact: true,
            icon: exhibitionsIcon,
        },
    ];

    if (withNotification) {
        menu.push({
            id: 2,
            title: 'Уведомления',
            to: `/nbc/${alias}/news-feed`,
            exact: false,
            icon: notificationsIcon,
        });
    }

    menu = [
        ...menu,
        {
            id: 4,
            title: 'Документы',
            to: `/nbc/${alias}/uploaded-documents/`,
            exact: false,
            icon: documentsIcon,
        },
        {
            id: 5,
            title: 'Фотогалерея',
            to: `/nbc/${alias}/gallery`,
            exact: false,
            icon: photosIcon,
        },
        {
            id: 6,
            title: 'Видеозаписи',
            to: `/nbc/${alias}/video`,
            exact: true,
            icon: videosIcon,
        },
        {
            id: 15,
            title: 'Cтраница НКП',
            to: `/nbc/${alias}`,
            exact: true,
            icon: pageIcon,
        },
    ];

    return menu;
};

export const nbcDocumentsMenu = alias => [
    {
        id: 1,
        title: 'Проведение выставок',
        to: `/nbc/${alias}/documents/`,
        exact: true,
        icon: exhibitionsIcon2,
    },
    {
        id: 2,
        title: 'Поиск по базе РКФ',
        to: `/base-search?nbcAlias=${alias}`,
        exact: true,
        icon: baseSearchIcon,
    },
    {
        id: 3,
        title: 'Страница НКП',
        to: `/nbc/${alias}`,
        exact: true,
        icon: pageIcon,
    },
];

export const userMenu = (alias, withNotification, isJudge) => {
    let menu = [
        {
            id: 1,
            title: 'Собаки',
            to: `/user/${alias}/dogs`,
            exact: true,
            disabled: true,
            icon: dogsIcon,
        },
    ];

    if (withNotification) {
        menu.push({
            id: 2,
            title: 'Уведомления',
            to: `/user/${alias}/news-feed?category_id=1`,
            exact: false,
            icon: notificationsIcon,
        });
    }

    menu = [
        ...menu,
        {
            id: 3,
            title: 'Документы',
            to: `/user/${alias}/uploaded-documents/`,
            exact: false,
            icon: documentsIcon,
        },
        {
            id: 4,
            title: 'Фотогалерея',
            to: `/user/${alias}/gallery`,
            exact: false,
            icon: photosIcon,
        },
        {
            id: 5,
            title: 'Видеозаписи',
            to: `/user/${alias}/video`,
            exact: true,
            icon: videosIcon,
        },
        {
            id: 6,
            title: 'Страница пользователя',
            to: `/user/${alias}`,
            exact: true,
            icon: pageIcon,
        },
    ];

    return menu;
};

export const userDocumentsMenu = (alias, isJudge) => {
    let menu = [
        {
            id: 1,
            title: 'Оформление документов',
            to: `/user/${alias}/documents`,
            exact: true,
            icon: documentsIcon2,
        },
        {
            id: 2,
            title: 'Организационная информация',
            to: `/user/${alias}/documents/responsible`,
            exact: true,
            icon: responsibleIcon,
        },
    ];

    if (isJudge) {
        menu.push({
            id: 3,
            title: 'Специализация',
            to: `/user/${alias}/documents/specialization`,
            exact: true,
            icon: specializationsIcon,
        });
    }

    menu = [
        ...menu,
        {
            id: 4,
            title: 'Мои собаки',
            to: `/user/${alias}/documents/dogs`,
            exact: true,
            disabled: true,
            icon: dogsIcon,
        },
        {
            id: 5,
            title: isJudge ? 'Мероприятия' : 'Запись на мероприятия',
            to: `/user/${alias}/documents/${isJudge ? 'exhibitions' : 'exhibitions-registration'}`,
            exact: true,
            disabled: !isJudge,
            icon: exhibitionsIcon,
        },
        {
            id: 6,
            title: 'Реквизиты и размеры взносов',
            to: `/user/${alias}/documents/bank-details`,
            exact: true,
            icon: requisitesIcon,
        },
        {
            id: 7,
            title: 'Оценка Федерации',
            to: `/user/${alias}/documents/federation-assessment`, //ссылка должна быть /documents/review
            exact: true,
            disabled: true,
            icon: reviewIcon,
        },
        {
            id: 8,
            title: 'Моя страница',
            to: `/user/${alias}`,
            exact: true,
            icon: pageIcon,
        },
    ];

    return menu;
};

export const federationMenu = (alias, requisitesLink, feesLink) => {
    let menu = [
        {
            id: 1,
            title: 'Мероприятия',
            to: `/exhibitions?Alias=${alias}`,
            exact: true,
            icon: exhibitionsIcon,
        },
        {
            id: 2,
            title: 'Президиум',
            isPresidium: true,
            alias: alias,
            icon: presidiumIcon,
        },
        {
            id: 3,
            title: 'Публикации',
            to: `/${alias}/news/`,
            exact: true,
            icon: newsIcon,
        },
        {
            id: 4,
            title: 'Документы',
            to: `/${alias}/uploaded-documents/`,
            exact: true,
            icon: documentsIcon,
        },
        {
            id: 5,
            title: 'Фотогалерея',
            to: `/${alias}/gallery`,
            exact: true,
            icon: photosIcon,

        },
        {
            id: 6,
            title: 'Видеозаписи',
            to: `/${alias}/video/`,
            exact: true,
            icon: videosIcon,
        },
    ];

    if (requisitesLink && feesLink) {
        menu = [
            ...menu,
            {
                id: 7,
                title: 'Размеры членских взносов',
                to: feesLink,
                target: '_blank',
                icon: walletIcon,
            },
            {
                id: 8,
                title: 'Реквизиты',
                to: requisitesLink,
                target: '_blank',
                icon: requisitesIcon,
            },
        ];
    }

    menu = [
        ...menu,
        {
            id: 9,
            title: 'Статус документов',
            to: `/${alias}/document-status/`,
            exact: true,
            icon: documentsIcon,
        },
        {
            id: 10,
            title: 'Страница федерации',
            to: `/${alias}`,
            exact: true,
            icon: pageIcon,
        },
    ];

    return menu;
};