import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { blockContent } from '../../utils/blockContent';
import './index.scss';


const Modal = ({
    showModal,
    handleClose,
    handleX,
    children,
    noBackdrop = false,
    className,
    headerName,
    iconName = 'default-modal-white',
    withCloseBtn = true,
    isGalleryModal,
    disableOutsideClick = false,
}) => {
    useEffect(() => {
        blockContent(showModal);
    }, [showModal, disableOutsideClick]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };

        window.addEventListener('keydown', close);

        return () => window.removeEventListener('keydown', close);
    },[]);

    return (
        ReactDOM.createPortal(
            <div className={(showModal ? 'Modal' : 'Modal--hidden') + (noBackdrop ? ' no-backdrop' : '') + (className ? ' ' + className : '')}>
                <OutsideClickHandler
                    onOutsideClick={handleClose}
                    disabled={disableOutsideClick}
                >
                    <div className="Modal__inner">
                        {!!headerName &&
                            <div className="Modal__main__header">
                                <div className="Modal__icon"
                                    style={{ background: `url(/static/icons/${iconName}.svg) center no-repeat` }}>
                                </div>
                                <h3 className="Modal__header" >{headerName}</h3>
                                {withCloseBtn &&
                                    <div className="Modal__close" onClick={handleX ? handleX : handleClose} />
                                }
                            </div>
                        }
                        {withCloseBtn && !isGalleryModal &&
                            <button type="button" className="Modal__close-btn" onClick={handleClose} />
                        }
                        <div className={'Modal__body' + (headerName ? '' : ' noheader')}>
                            {isGalleryModal &&
                                <button type="button" className="Modal__close-btn" onClick={handleClose} />
                            }
                            {children}
                        </div>
                    </div>
                </OutsideClickHandler>
            </div>, document.body
        )
    );
};

export const VideoModal = ({ showModal, handleClose, children, noBackdrop = false, className }) => {
    return (
        ReactDOM.createPortal(
            <div className={(showModal ? 'Modal' : 'Modal--hidden') + (noBackdrop ? ' no-backdrop' : '') + (className ? ' ' + className : '') + ' VideoModal'}>
                <OutsideClickHandler onOutsideClick={handleClose}>
                    <div className="VideoModal__close" onClick={handleClose} />
                    {children}
                </OutsideClickHandler>
            </div>, document.body
        )
    );
};

export default Modal;
