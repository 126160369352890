import React from 'react';


const Svg = ({ className, children, ...restProps }) => (
    <svg
        className={`btn-i${className ? ' ' + className : ''}`}
        {...restProps}
    >
        {children}
    </svg>
);

const Path = ({ d, fill }) => (
    <path
        d={d}
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
    />
);


export const DeleteIcon = ({ className, width, height }) => (
    <Svg
        className={className}
        width={width || '13px'} height={height || '18px'} viewBox="0 0 13 18">
        <Path d="M8.18772 18H3.85535C2.90119 18 2.14045 17.2264 2.14045 16.2851L0.0129395 5.66046C0.0129395 4.59026 0.773685 3.81662 1.71495 3.81662H10.3152C11.2565 3.81662 12.0301 4.59026 12.0301 5.53152V5.66046L9.88973 16.4011C9.88973 17.2264 9.12899 18 8.18772 18V18ZM1.72784 5.10602C1.49575 5.10602 1.30234 5.29943 1.30234 5.53152L3.41695 16.1562C3.41695 16.5172 3.62325 16.7106 3.85535 16.7106H8.18772C8.41982 16.7106 8.61323 16.5172 8.61323 16.2851L10.7407 5.49284C10.7149 5.27364 10.5344 5.11891 10.3152 5.11891H1.72784V5.10602Z" fill="#FF6977"/>
        <Path d="M11.3983 2.55301H0.644699C0.283668 2.55301 0 2.26934 0 1.90831C0 1.54728 0.283668 1.26361 0.644699 1.26361H11.3983C11.7593 1.26361 12.043 1.54728 12.043 1.90831C12.043 2.26934 11.7593 2.55301 11.3983 2.55301Z" fill="#FF6977"/>
        <Path d="M6.29216 2.55301C5.93113 2.55301 5.64746 2.26934 5.64746 1.90831V0.644699C5.64746 0.283668 5.93113 0 6.29216 0C6.65319 0 6.93686 0.283668 6.93686 0.644699V1.90831C6.93686 2.26934 6.65319 2.55301 6.29216 2.55301Z" fill="#FF6977"/>
    </Svg>
);

export const PdfIcon = ({ className }) => (
    <Svg
        className={className}
        width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M3.96236 12.6448C3.96236 12.3187 3.73408 12.123 3.33459 12.123C3.17153 12.123 3.06146 12.1394 3.00439 12.1557V13.2033C3.07369 13.2196 3.15522 13.2237 3.27344 13.2237C3.70146 13.2237 3.96236 13.0076 3.96236 12.6448Z" fill="#3366FF"/>
        <Path d="M6.43653 12.1357C6.25717 12.1357 6.14303 12.152 6.07373 12.1684V14.4878C6.14303 14.5041 6.25309 14.5041 6.35093 14.5041C7.07654 14.5082 7.5494 14.1087 7.5494 13.2649C7.55756 12.523 7.12545 12.1357 6.43653 12.1357Z" fill="#3366FF"/>
        <Path d="M11.0349 0H3.13885C1.98522 0 1.04764 0.93758 1.04764 2.09121V9.82828H0.843822C0.379108 9.82828 0 10.2074 0 10.6721V15.7839C0 16.2487 0.379108 16.6278 0.843822 16.6278H1.04764V17.5654C1.04764 18.719 1.98522 19.6566 3.13885 19.6566H13.9088C15.0624 19.6566 16 18.719 16 17.5654V4.94879L11.0349 0ZM2.2135 11.5934C2.46217 11.5526 2.80866 11.52 3.29783 11.52C3.79108 11.52 4.14573 11.6138 4.38217 11.8054C4.60637 11.9847 4.76127 12.2782 4.76127 12.6247C4.76127 12.9712 4.64713 13.2647 4.43516 13.4645C4.16204 13.7213 3.75847 13.8395 3.28153 13.8395C3.17554 13.8395 3.08178 13.8354 3.00841 13.8232V15.091H2.2135V11.5934ZM13.9088 18.3725H3.13885C2.69045 18.3725 2.32764 18.0097 2.32764 17.5613V16.6237H12.3679C12.8326 16.6237 13.2117 16.2446 13.2117 15.7799V10.668C13.2117 10.2033 12.8326 9.8242 12.3679 9.8242H2.32764V2.09121C2.32764 1.64688 2.69045 1.28 3.13885 1.28L10.5539 1.27185V4.01121C10.5539 4.81019 11.202 5.46242 12.0051 5.46242L14.6915 5.45427L14.72 17.5572C14.72 18.0097 14.3572 18.3725 13.9088 18.3725ZM5.27083 15.0746V11.5934C5.56433 11.5485 5.94752 11.52 6.35516 11.52C7.02777 11.52 7.46395 11.6423 7.80637 11.8991C8.17325 12.1722 8.40561 12.6084 8.40561 13.2362C8.40561 13.9129 8.15694 14.3817 7.81452 14.6711C7.43949 14.9809 6.87287 15.1276 6.17987 15.1276C5.76408 15.1276 5.47057 15.0991 5.27083 15.0746ZM11.0104 13.012V13.6642H9.7386V15.091H8.93554V11.5445H11.1042V12.2008H9.74268V13.012H11.0104Z" fill="#3366FF"/>
    </Svg>
);

export const CrossIcon = ({ className, width, height }) => (
    <svg
        className={className}
        width={width || '10,5px'} height={height || '10,5px'} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z" fill="#90999E"/>
    </svg>
);

export const ChangeIcon = ({ className, width, height }) => (
    <svg
        className={className}
        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8332 13.8183C8.32209 15.0147 5.32456 14.502 3.3525 12.5299C1.07806 10.2555 0.815117 6.71892 2.62941 4.12895L2.8792 5.4305L4.16762 5.18071L3.48397 1.68359L0 2.38039L0.249794 3.6688L1.53821 3.419C-0.631059 6.53486 -0.315529 10.7419 2.41906 13.4634C3.93097 14.9753 5.95562 15.7772 8.00656 15.7772C9.15035 15.7772 10.3204 15.5274 11.3985 15.0147C11.7272 14.8569 11.8586 14.4625 11.714 14.1339C11.5563 13.8052 11.1618 13.6606 10.8332 13.8183Z" fill="#8F989D"/>
        <path d="M15.7371 12.1091L14.4487 12.3589C16.618 9.24304 16.3025 5.04912 13.5679 2.31454C11.1883 -0.0782273 7.54652 -0.682992 4.50955 0.815773C4.18087 0.973537 4.0494 1.36795 4.20717 1.69663C4.36493 2.0253 4.75934 2.15677 5.08802 1.99901C7.61225 0.763184 10.6492 1.26277 12.6344 3.24798C14.922 5.53557 15.1718 9.04583 13.3575 11.6489L13.1077 10.3474L11.8193 10.5972L12.503 14.0943L16.0001 13.4107L15.7503 12.1222L15.7371 12.1091Z" fill="#8F989D"/>
    </svg>
);